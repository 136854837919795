<template>
  <v-container>
    <v-row><AddCard /></v-row
  ></v-container>
</template>

<script>
import AddCard from "../components/AddCard.vue";
export default {
  components: {
    AddCard,
  },
};
</script>

<style></style>
