var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pb-7 mb-15",attrs:{"flat":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"#1f4563","flat":""}},[_c('v-spacer'),(_vm.user)?_c('h2',[_vm._v("Add A Link")]):_c('h2',[_vm._v("Make a QR Code")]),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',[_c('v-card-subtitle',[_c('b',[_vm._v("Customize your QR")])]),_c('v-form',{staticClass:"pa-5"},[_c('v-text-field',{attrs:{"label":"Link URL","persistent-hint":"","hint":"http://facebook.com/account","rules":[
                    function (v) { return !!v || 'Need a link!'; },
                    function (v) { return /^http:\/\/|^https:\/\//gi.test(v) ||
                      'Needs to have http://'; } ],"required":""},on:{"blur":function (e) { return (
                      (_vm.link.options.data =
                        _vm.link.link || 'http://mintyid.netlify.app'),
                      _vm.log(e)
                    ); }},model:{value:(_vm.link.link),callback:function ($$v) {_vm.$set(_vm.link, "link", $$v)},expression:"link.link"}}),_c('v-text-field',{attrs:{"label":"Link Name","hint":"Facebook / Twitter / TikTok..etc","rules":[function (v) { return !!v || 'What is this link called?'; }],"required":""},model:{value:(_vm.link.name),callback:function ($$v) {_vm.$set(_vm.link, "name", $$v)},expression:"link.name"}}),_c('v-combobox',{attrs:{"clearable":"","label":"Category","items":_vm.categories}}),_c('v-combobox',{attrs:{"items":_vm.icons,"label":"Link Icon","prepend-icon":_vm.link.icon,"single-line":""},model:{value:(_vm.link.icon),callback:function ($$v) {_vm.$set(_vm.link, "icon", $$v)},expression:"link.icon"}}),_c('v-switch',{attrs:{"label":"Logo","value":"/mintyqrcode.png"},model:{value:(_vm.link.options.image),callback:function ($$v) {_vm.$set(_vm.link.options, "image", $$v)},expression:"link.options.image"}})],1),_c('v-divider',{staticClass:"mb-10"}),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":[
                              'rounded',
                              'dots',
                              'classy',
                              'classy-rounded',
                              'square',
                              'extra-rounded' ],"menu-props":{ bottom: true, offsetY: true },"label":"Dots Type"},model:{value:(_vm.link.options.dotsOptions.type),callback:function ($$v) {_vm.$set(_vm.link.options.dotsOptions, "type", $$v)},expression:"link.options.dotsOptions.type"}})],1)],1)]],2),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":['dot', 'square', 'extra-rounded'],"menu-props":{ bottom: true, offsetY: true },"label":"Corner Square Type"},model:{value:(_vm.link.options.cornersSquareOptions.type),callback:function ($$v) {_vm.$set(_vm.link.options.cornersSquareOptions, "type", $$v)},expression:"link.options.cornersSquareOptions.type"}})],1)],1)]],2),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":['dot', 'square'],"menu-props":{ bottom: true, offsetY: true },"label":"Corner Dot Type"},model:{value:(_vm.link.options.cornersDotOptions.type),callback:function ($$v) {_vm.$set(_vm.link.options.cornersDotOptions, "type", $$v)},expression:"link.options.cornersDotOptions.type"}})],1)],1)]],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.link.options.dotsOptions.color,"fab":""}},on))]}}])},[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(_vm.link.options.dotsOptions.color),callback:function ($$v) {_vm.$set(_vm.link.options.dotsOptions, "color", $$v)},expression:"link.options.dotsOptions.color"}})],1),_c('v-card-text',[_vm._v("Dots Color")])],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.link.options.cornersSquareOptions.color,"fab":""}},on))]}}])},[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(_vm.link.options.cornersSquareOptions.color),callback:function ($$v) {_vm.$set(_vm.link.options.cornersSquareOptions, "color", $$v)},expression:"link.options.cornersSquareOptions.color"}})],1),_c('v-card-text',[_vm._v("Corner Square Color")])],1),_c('v-col',{attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":_vm.link.options.cornersDotOptions.color,"fab":""}},on))]}}])},[_c('v-color-picker',{attrs:{"hide-inputs":""},model:{value:(_vm.link.options.cornersDotOptions.color),callback:function ($$v) {_vm.$set(_vm.link.options.cornersDotOptions, "color", $$v)},expression:"link.options.cornersDotOptions.color"}})],1),_c('v-card-text',[_vm._v(" Corner Dots Color")])],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',[_c('v-col',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('QR',{attrs:{"options":_vm.link.options}})],1)],1)],1)],1)],1)],1),(_vm.user)?_c('v-card-actions',[_c('v-btn',{attrs:{"color":"#7ed957","block":"","disabled":!_vm.user},on:{"click":function($event){return _vm.addLink()}}},[_c('b',[_vm._v("Add")])])],1):_c('div',[_c('Auth'),_c('h4',[_vm._v("You will need to sign it to save it!")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }